import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M41 41.262V12.517h-7.222V8L15 12.845V44h18.778V15.255h4.333V44H41v-2.738z"
      ></path>
      <path
        fill="#003E7F"
        d="M18 26.349l5.273-.88v6.152L18 32.5v-6.151zM24.94 25.294l5.272-.879v6.152l-5.273.879v-6.152zM21.424 17.879L26.697 17v6.151l-5.273.88v-6.152z"
      ></path>
    </svg>
  )
}

export default Icon
