import React from 'react'

function PhotographyDarkRoom() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        d="M52.335 14.58c-2.74.7-6.778 1.777-9.57 2.226a.626.626 0 00-.354.056 93.906 93.906 0 01-14.892 1.138c-7.719-.028-17.507-1.467-24.977-3.375a.625.625 0 00-.665.211.601.601 0 00-.121.459c.012.08.188 1.1.231 1.169a.612.612 0 00.393.274c2.126.543 6.374 1.47 8.534 1.864v3.844H7.307a.829.829 0 00-.58.237.8.8 0 00-.241.57v12.923a.8.8 0 00.24.57.829.829 0 00.581.237h8.64a.83.83 0 00.58-.236.8.8 0 00.241-.571V23.253a.8.8 0 00-.24-.57.83.83 0 00-.581-.237H12.14v-3.632a95.172 95.172 0 0014.379 1.322v4.324h-3.708a.83.83 0 00-.58.236.8.8 0 00-.241.571v12.927a.8.8 0 00.24.57.83.83 0 00.581.237h8.639a.83.83 0 00.58-.237.8.8 0 00.242-.57V25.27a.8.8 0 00-.241-.57.83.83 0 00-.581-.237h-3.704v-4.31h.249c4.758-.002 9.51-.359 14.212-1.07v3.362h-3.891a.83.83 0 00-.581.237.8.8 0 00-.241.57v12.923a.8.8 0 00.24.57.83.83 0 00.582.237h8.64a.829.829 0 00.58-.236.8.8 0 00.24-.571V23.253a.8.8 0 00-.24-.57.829.829 0 00-.58-.237H43.44V18.89c2.712-.446 6.678-1.514 9.348-2.196a.621.621 0 00.394-.274.597.597 0 00.072-.469c-.021-.078-.205-1.094-.255-1.159a.613.613 0 00-.42-.232.627.627 0 00-.245.021z"
      ></path>
    </svg>
  )
}

export default PhotographyDarkRoom
