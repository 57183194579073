import React from 'react'

function OtherSpaceTypeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        fill="#003E7F"
        d="M27.5 55C42.688 55 55 42.688 55 27.5S42.688 0 27.5 0 0 12.312 0 27.5 12.312 55 27.5 55z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.5 22c3.035 0 5.5 2.465 5.5 5.5 0 3.037-2.465 5.5-5.5 5.5a5.502 5.502 0 01-5.5-5.5c0-3.037 2.465-5.5 5.5-5.5zm0 2.334a3.168 3.168 0 00-3.167 3.166 3.168 3.168 0 003.166 3.167 3.17 3.17 0 003.168-3.167 3.17 3.17 0 00-3.168-3.166zM39.5 22c3.037 0 5.5 2.465 5.5 5.5 0 3.037-2.465 5.5-5.5 5.5a5.503 5.503 0 01-5.5-5.5c0-3.037 2.465-5.5 5.5-5.5zm0 2.334a3.17 3.17 0 00-3.167 3.166 3.17 3.17 0 003.168 3.167 3.168 3.168 0 003.166-3.167 3.168 3.168 0 00-3.166-3.166zM27.5 22c3.037 0 5.5 2.465 5.5 5.5 0 3.037-2.465 5.5-5.5 5.5a5.502 5.502 0 01-5.5-5.5c0-3.037 2.465-5.5 5.5-5.5zm0 2.334a3.168 3.168 0 00-3.166 3.166 3.168 3.168 0 003.166 3.167 3.168 3.168 0 003.166-3.167 3.168 3.168 0 00-3.166-3.166z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default OtherSpaceTypeIcon
